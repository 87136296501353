<template>
    <div class="container content">
        <div class="headline">
            <h1>{{ $t("message.main.login.reset-password") }}</h1>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-4">
                <article class="tile is-child box">
                <p class="title">{{ $t('message.reset-password.instructions.header') }}</p>
                <div v-html="$t('message.reset-password.instructions.txt')"></div>
                </article>
            </div>
            <div class="tile is-parent is-8">
                <article class="tile is-child box">
                    <p class="title">{{ $t('message.reset-password.header') }}</p>
                        <b-field :label="$t('message.register-user.add.email')">
                        <b-input type="email" value="" v-model="credentials.email">
                        </b-input>
                    </b-field>
                    <b-field :label="$t('message.register-user.add.password')">
                        <b-input
                            type="password"
                            value=""
                            v-model="credentials.password"
                            password-reveal
                        >
                        </b-input>
                    </b-field>

                    <b-field :label="$t('message.register-user.add.confirm-password')">
                        <b-input
                            type="password"
                            value=""
                            v-model="credentials.password_confirmation"
                            password-reveal
                        >
                        </b-input>
                    </b-field>
                    <b-button
                        :loading="false"
                        :label="$t('message.register-user.add.send')"
                        size="is-large"
                        class="fullwidth"
                        type="is-success"
                        v-on:click="setNewPassword"
                    />
                </article>
            </div>
        </div>  
    </div>
</template>

<script>
import EventBus from "@/eventBus";
export default {
    name: "ResetPassword",
    data() {
        return {
            credentials: {
                token: false,
                email: "",
                password: "",
                password_confirmation: "",
            },
        };
    },
    mounted() {
        let token = this.$route.params["resetToken"];
        if (token !== undefined) {
            this.credentials.token = token;
        } else {
            this.goTo("Home");
        }
    },
    methods: {
        setNewPassword() {
            if (this.password == this.password_confirmation) {
                EventBus.$emit("startLoading", "login");
                window.axios
                    .post(
                        "reset-password/" + this.credentials.token,
                        this.credentials
                    )
                    .then((response) => {
                        if (
                            response.data == "passwords.reset" ||
                            response.data == "passwords.token"
                        ) {
                            this.toastSuccess("password.reseted");
                            this.$store
                                .dispatch("tryLogin", this.credentials)
                                .then((response2) => {
                                    if (response2.data.token) {
                                        this.emit(
                                            "setToken",
                                            response2.data.token
                                        );
                                        EventBus.$emit("stopLoading", "login");
                                        this.goTo("Home");
                                    } else {
                                        this.toastFailure("login.failed");
                                    }
                                    EventBus.$emit("stopLoading", "login");
                                })
                                .catch((error2) => {
                                    EventBus.$emit("stopLoading", "login");
                                    this.ERROR(error2);
                                    this.toastFailure("server.error");
                                });
                        } else {
                            this.toastFailure(response.data);
                            EventBus.$emit("stopLoading", "login");
                        }
                        console.log(response);
                    })
                    .catch((error) => {
                        console.log(error);
                        this.toastFailure("server.error");
                        EventBus.$emit("stopLoading", "login");
                    });
            } else {
                this.toastFailure("password.confirmation.error");
            }
        },
    },
};
</script>

