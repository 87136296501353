<template>
    <div id="app">
        <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="false"
        ></b-loading>
        <div class="container">
            <div id="topbar" class="columns">
                <div id="logo" class="column is-one-fifth bluebg">
                    <a href="/"><img src="/img/adinum_logo_white.png" /></a>
                </div>
                <b-button
                    v-if="user"
                    class="kirjaudu-ulos-mobile"
                    icon-left="logout"
                />
                <div id="slogan" class="column is-two-fifths">
                    {{ $t("message.main.slogan") }}
                </div>
                <div id="userinfo" class="column">
                    <b-button
                        v-if="!user"
                        :label="$t('message.main.login.login')"
                        type="is-success"
                        icon-left="login"
                        @click="modalActive = true"
                    />
                    <b-button
                        :label="$t('message.main.login.logout')"
                        class="kirjaudu-ulos"
                        icon-left="logout"
                        v-if="user"
                        @click="logout()"
                    />
                    <p class="kirjautunut-info" v-if="user">
                        Hei <b>{{ user.first_name }}</b
                        ><br /><router-link :to="linkTo('Profile')"><u>{{
                                $t("message.main.login.own-info-link")
                            }}</u></router-link
                        >
                    </p>
                    <b-modal
                        v-model="modalActive"
                        @close="closeModal"
                        has-modal-card
                        trap-focus
                        :destroy-on-hide="false"
                        aria-role="dialog"
                        aria-modal
                    >
                        <form action="">
                            <div class="modal-card" style="width: auto">
                                <header class="modal-card-head">
                                    <p class="modal-card-title">
                                        {{ modalTitle }}
                                    </p>
                                    <button
                                        type="button"
                                        class="delete"
                                        @click="closeModal"
                                    />
                                </header>
                                <section class="modal-card-body">
                                    <b-field
                                        :label="$t('message.main.login.email')"
                                    >
                                        <b-input
                                            type="email"
                                            v-model="credentials.email"
                                            @keyup.enter.native="tryLogin"
                                            :placeholder="
                                                $t(
                                                    'message.main.login.email-placeholder'
                                                )
                                            "
                                            required
                                        >
                                        </b-input>
                                    </b-field>

                                    <b-field
                                        v-if="modalType == 'login'"
                                        :label="
                                            $t('message.main.login.password')
                                        "
                                    >
                                        <b-input
                                            v-if="modalType == 'login'"
                                            type="password"
                                            v-model="credentials.password"
                                            password-reveal
                                            @keyup.enter.native="tryLogin"
                                            :placeholder="
                                                $t(
                                                    'message.main.login.password-placeholder'
                                                )
                                            "
                                            required
                                        >
                                        </b-input>
                                    </b-field>
                                </section>
                                <footer class="modal-card-foot">
                                    <b-button
                                        v-if="modalType == 'login'"
                                        @click="tryLogin"
                                        :label="$t('message.main.login.login')"
                                        icon-left="login"
                                        class="is-success kirjaudumodal"
                                        type="is-primary"
                                    />
                                    <b-button
                                        v-if="modalType != 'login'"
                                        @click="tryResetPassword"
                                        :label="
                                            $t(
                                                'message.main.login.reset-password'
                                            )
                                        "
                                        icon-left="login"
                                        class="is-warning kirjaudumodal"
                                        type="is-primary"
                                    />
                                    <p>
                                        <span
                                            v-if="modalType == 'login'"
                                            @click="
                                                modalType = 'forgot-password'
                                            "
                                            class="unohditko-salasanan is-link"
                                            >{{
                                                $t(
                                                    "message.main.login.forgot-password"
                                                )
                                            }}</span
                                        >
                                        <span
                                            v-if="
                                                modalType == 'forgot-password'
                                            "
                                            @click="modalType = 'login'"
                                            class="unohditko-salasanan is-link"
                                            >{{
                                                $t("message.main.login.login")
                                            }}</span
                                        >
                                    </p>
                                </footer>
                            </div>
                        </form>
                    </b-modal>
                </div>
            </div>
            <AdminNavigation @toggleBurger="toggleburger" :burgerclass="burgerclass" v-if="activeUser && activeUser.type == 'admin'" />
            <GuestNavigation @toggleBurger="toggleburger" :burgerclass="burgerclass" v-if="!activeUser" />
            <UserNavigation @toggleBurger="toggleburger" :burgerclass="burgerclass" v-if="activeUser && activeUser.type == 'enduser'" />
            <CompanyNavigation @toggleBurger="toggleburger" :burgerclass="burgerclass" v-if="activeUser && activeUser.type == 'company'" />
            <PartnerNavigation @toggleBurger="toggleburger" :burgerclass="burgerclass" v-if="activeUser && activeUser.type == 'education_partner'" />
        
        </div>
        <div  id="flags" class="container">
            <div class="column is-seven-eights">&nbsp;</div>
            <div class="columns" v-if="activeUser.type != 'admin'">
                <a v-on:click="changeLang('fi')"><country-flag country='fi' size='normal'/></a>
                <a v-on:click="changeLang('en')"><country-flag country='gb' size='normal'/></a>
            </div>
        </div>
        <router-view />
        <footer>
            <div id="tarvitsetko-apua">
                <div class="container">
                    <div class="columns">
                        <div class="column is-one-fifth">
                            <img src="/img/tuki.png" />
                        </div>
                        <div class="column">
                            <p class="subtitle">
                                {{ $t("message.main.need-help.header") }}
                            </p>
                            <p>
                                {{ $t("message.main.need-help.txt") }}
                                <br /><br />
                                <b v-html="$t('message.main.need-help.phone-sales')"></b><br />
                                <b v-html="$t('message.main.need-help.phone-customer-service')"></b>
                                <br />
                                <b v-html="$t('message.main.need-help.email')"></b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="mika-on-koulutusseteli">
                <div class="container">
                    <div class="columns">
                        <div class="column is-one-fifth">
                            <img src="/img/adinum_logo_white.png" />
                        </div>
                        <div class="column">
                            <p>
                                {{ $t("message.main.what-is") }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="copyright">
                <div class="container">
                    <div class="columns">
                        <div class="column">
                            <p>© Adinum Oy {{ currentYear }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import AdminNavigation from "@/components/nav/AdminNavigation";
import GuestNavigation from "@/components/nav/GuestNavigation";
import UserNavigation from "@/components/nav/UserNavigation";
import CompanyNavigation from "@/components/nav/CompanyNavigation";
import PartnerNavigation from "@/components/nav/PartnerNavigation";

import EventBus from "@/eventBus";
import Vue from "vue";
import axios from "axios";

// Add a 401 response interceptor
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        EventBus.$emit('401');
    }
    else if (503 === error.response.status) {
        EventBus.$emit('503');
    }
    else {
        return Promise.reject(error);
    }
});

export default {
    components: {
        AdminNavigation,
        GuestNavigation,
        UserNavigation,
        CompanyNavigation,
        PartnerNavigation
    },
    data() {
        return {
            maintinanceMode: false,
            loaderStates: {},
            isLoading: false,
            modalType: "login",
            modalActive: false,
            rememberLogin: false,
            credentials: {
                email: "",
                password: "",
            },
            burger: false,
        };
    },
    created() {
        if (sessionStorage.token !== undefined) {
            window.axios.defaults.headers.common["Authorization"] =
                "Bearer" + " " + sessionStorage.token;
            this.$store.dispatch("whoami");
        }
        EventBus.$on('401',()=> {
            this.modalActive = true;
        })
        EventBus.$on('503',()=> {
            console.log('HUOLTOTILA');
        })
    },
    mounted() {
        EventBus.$on("startLoading", (loaderName) => {
            this.LOG(this.loaderStates, loaderName);
            if (this.loaderStates[loaderName] === undefined) {
                Vue.set(this.loaderStates, loaderName, true);
            } else {
                this.loaderStates[loaderName] = true;
            }
        });
        EventBus.$on("stopLoading", (loaderName) => {
            this.LOG(this.loaderStates, loaderName);
            if (this.loaderStates[loaderName] === undefined) {
                Vue.set(this.loaderStates, loaderName, false);
            } else {
                this.loaderStates[loaderName] = false;
            }
        });
        EventBus.$on("logout", () => {
            sessionStorage.clear();
            delete window.axios.defaults.headers.common["Authorization"];
            this.$store.dispatch("logout");
            this.$router.push("/");
        });
        EventBus.$on("setToken", (token) => {
            sessionStorage.token = token;
            window.axios.defaults.headers.common["Authorization"] =
                "Bearer" + " " + token;
        });
        EventBus.$on("toastSuccess", (message) => {
            this.$buefy.toast.open({
                message: message,
                type: "is-success",
                position: "is-top-right",
            });
        });
        EventBus.$on("toastFailure", (message) => {
            this.$buefy.toast.open({
                message: message,
                type: "is-danger",
                position: "is-top-right",
            });
        });

        if (sessionStorage.token != null) {
            this.emit("setToken", sessionStorage.token);
        }
    },
    computed: {
        modalTitle() {
            if (this.modalType == "login") {
                return this.$t("message.main.login.login");
            }
            return this.$t("message.main.login.forgot-password");
        },
        burgerclass() {
            if (this.burger) {
                return "is-active";
            }
            return "";
        },
        user() {
            return this.$store.getters["getUser"];
        },
        currentYear() {
            return new Date().getFullYear();
        }
    },
    methods: {
        changeLang(_lang) {
            //console.log("lang is", _lang, this.$i18n)
            this.$i18n.locale = _lang
        },
        tryLogin() {
            EventBus.$emit("startLoading", "login");
            this.$store
                .dispatch("tryLogin", this.credentials)
                .then((response) => {
                    if (response.data.token) {
                        this.emit("setToken", response.data.token);
                        this.closeModal();
                        if(response.data.user.type == 'admin') {
                            this.goTo("AdminDashboard");
                        }
                        else {
                            this.goTo("Home");
                        }
                        
                    } else {
                        this.toastFailure(this.$t("message.error.invalid-email-or-password"));
                    }
                    EventBus.$emit("stopLoading", "login");
                })
                .catch((error) => {
                    EventBus.$emit("stopLoading", "login");
                    this.ERROR(error);
                    this.toastFailure("Login failed (server issue)");
                });
        },
        tryResetPassword() {
            EventBus.$emit("startLoading", "resetPassword");
            axios
                .post("/reset-password", {
                    email: this.credentials.email,
                    browser: window.navigator,
                })
                .then((response) => {
                    if (response.data && response.data.success) {
                        this.closeModal();
                        this.toastSuccess(
                            this.$t("message.main.login.password-reset-success")
                        );
                    } else {
                        this.toastFailure(
                            this.$t("message.main.login.password-reset-failed")
                        );
                    }
                    EventBus.$emit("stopLoading", "resetPassword");
                })
                .catch((error) => {
                    EventBus.$emit("stopLoading", "resetPassword");
                    this.ERROR(error);
                    this.toastFailure(
                        this.$t("message.main.login.password-reset-failed")
                    );
                });
        },
        closeModal() {
            this.credentials.email = "";
            this.credentials.password = "";
            this.rememberLogin = false;
            this.modalType = "login";
            this.modalActive = false;
        },
        toggleburger() {
            this.burger = !this.burger;
        },
        logout() {
            this.emit("logout");
        },
    },
    watch: {
        loaderStates: {
            deep: true,
            handler(oldVal, newVal) {
                console.log(oldVal, newVal);
                let check = false;
                let keys = Object.keys(this.loaderStates);
                keys.forEach((key) => {
                    if (this.loaderStates[key]) {
                        check = true;
                    }
                });
                this.isLoading = check;
            },
        },
    },
};
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #874d78;
$primary-light: findLightColor($primary);
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);
$twitter: #4099ff;
$twitter-invert: findColorInvert($twitter);

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(
    (
        "white": (
            $white,
            $black,
        ),
        "black": (
            $black,
            $white,
        ),
        "light": (
            $light,
            $light-invert,
        ),
        "dark": (
            $dark,
            $dark-invert,
        ),
        "primary": (
            $primary,
            $primary-invert,
            $primary-light,
            $primary-dark,
        ),
        "link": (
            $link,
            $link-invert,
            $link-light,
            $link-dark,
        ),
        "info": (
            $info,
            $info-invert,
            $info-light,
            $info-dark,
        ),
        "success": (
            $success,
            $success-invert,
            $success-light,
            $success-dark,
        ),
        "warning": (
            $warning,
            $warning-invert,
            $warning-light,
            $warning-dark,
        ),
        "danger": (
            $danger,
            $danger-invert,
            $danger-light,
            $danger-dark,
        ),
    ),
    $custom-colors
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

.is-link {
    cursor: pointer;
    color: $link;
    &:hover {
        text-decoration: underline;
    }
}
ul.pagination-list {
margin-top:0;
}
ul.pagination-list li + li {
margin-top:0;
}
#app .pagination-link.is-current {
background-color:#283B6B;
border-color:#283B6B;
color:#FFF;
}
.b-tabs {
width:100%;
}
.custom-tabs {
    padding:30px 0 0 0 !important;
}
.tab-content {
    display:none !important;
}
.tabs.is-boxed li.is-active a {
border-color:#FFF !important;
background:#FFF !important;
border-radius:0 !important;
}
.tabs.is-boxed li a {
border-color:#f0f0f0 !important;
padding-left:25px !important;
padding-right:25px !important;
background:#f0f0f0 !important;
border-radius:0 !important; 
}
.b-tabs .tabs ul {
margin:0;
border:none;
}
</style>
