import axios from "axios";

//to handle state
const state = {
    company: null,
    companies: [],
    employees: [],
    newOrders: [],
    billedOrders: [],
    paidOrders: [],
    users: [],
    partners: [],
    user: null,
    courses: [],
    courses2: [],
    enrollments: [],
    categories: []
};

//to handle state
const getters = {
    getCompany: (state) => {
        return state.company;
    },
    getCompanies: (state) => {
        return state.companies;
    },
    getEmployees: (state) => {
        return state.employees;
    },
    getNewOrders: (state) => {
        return state.newOrders;
    },
    getBilledOrders: (state) => {
        return state.billedOrders;
    },
    getPaidOrders: (state) => {
        return state.paidOrders;
    },
    getPartners: (state) => {
        return state.partners;
    },
    getUsers: (state) => {
        return state.users;
    },
    getUser: (state) => {
        return state.user;
    },
    getCourses: (state) => {
        return state.courses;
    },
    getCourses2: (state) => {
        return state.courses2;
    },
    getEnrollments: (state) => {
        return state.enrollments;
    },
    getCategories: (state) => {
        return state.categories
    }
};

//to handle actions
const actions = {
    fetchCompany({ commit }, _id) {
        axios.get("/companies/" + _id).then((response) => {
            commit("SET_COMPANY", response.data);
        });
    },
    fetchCompanies({ commit }) {
        axios.get("/companies").then((response) => {
            commit("SET_COMPANIES", response.data);
        });
    },
    fetchCompanies2() {
        return axios.get("/companies")
    },
    fetchPartners({ commit }) {
        axios.get("/education-partners").then((response) => {
            commit("SET_PARTNERS", response.data);
        });
    },
    fetchPartners2() {
        return axios.get("/education-partners")
    },
    fetchUsers({ commit }) {
        axios.get("/users").then((response) => {
            commit("SET_USERS", response.data);
        });
    },
    fetchCategories({ commit }) {
        axios.get("/categories").then((response) => {
            commit("SET_CATEGORIES", response.data);
        });
    },
    fetchCategories2() {
        return axios.get("/categories")
    },
    createCategory(_context, category) {
        return axios.post("/categories", category);
    },
    createCompany(_context, company) {
        return axios.post("/companies", company);
    },
    editCompany(_context, company) {
        return axios.put("/companies", company);
    },
    validateCompany(_context, company) {
        return axios.post("/companies/validate", company);
    },
    createPartner(_context, partner) {
        return axios.post("/education-partners", partner);
    },
    editPartner(_context, partner) {
        return axios.put("/education-partners", partner);
    },
    validatePartner(_context, partner) {
        return axios.post("/education-partners/validate", partner);
    },
    createUser(_context, user) {
        return axios.post("/register", user);
    },
    editUser(_context, user) {
        return axios.put("/users/" + user.id, user);
    },
    validateUser(_context, user) {
        return axios.post("/users/validate", user);
    },
    fetchEmployees({ commit }) {
        axios.get("/employees").then((response) => {
            commit("SET_EMPLOYEES", response.data);
        });
    },
    fetchEmployees2() {
        return axios.get("/employees");
    },
    fetchEducations({ commit }) {
        axios.get("/courses").then((response) => {
            commit("SET_COURSES", response.data);
        });
    },
    fetchEducations2({ commit }) {
        axios.get("/courses/search").then((response) => {
            commit("SET_COURSES_2", response.data);
        });
    },
    fetchEducations3() {
        return axios.get("/courses")
    },
    createEmployee(_context, employee) {
        return axios.post("/employees", employee);
    },
    createEducation(_context, education) {
        return axios.post("/courses", education);
    },
    editEducation(_context, education) {
        return axios.put("/courses/" + education.id, education);
    },
    unenroll(_context, enrollment) {
        return axios.delete("/enrollments/" + enrollment.id);
    },
    enroll(_context, enrollment) {
        return axios.post("/enrollments", enrollment);
    },
    fetchEnrollments({ commit }) {
        axios.get("/enrollments").then((response) => {
            commit("SET_ENROLLMENTS", response.data);
        });
    },
    publishEducation(_context, _object) {
        var status = {
            status: _object.status,
        };
        return axios.put("/courses/" + _object.id, status);
    },
    createOrder(_context, order) {
        return axios.post("/orders", order);
    },
    fetchNewOrders({ commit }) {
        axios.get("/orders/bystatus/new-order").then((response) => {
            commit("SET_NEW_ORDERS", response.data);
        });
    },
    fetchBilledOrders({ commit }) {
        axios.get("/orders/bystatus/billed").then((response) => {
            commit("SET_BILLED_ORDERS", response.data);
        });
    },
    fetchPaidOrders({ commit }) {
        axios.get("/orders/bystatus/paid").then((response) => {
            commit("SET_PAID_ORDERS", response.data);
        });
    },
    changeOrderStatus(_context, _object) {
        var status = {
            status: _object.status,
        };
        return axios.put("/orders/" + _object.id, status);
    },
    createLogo(_context, file) {
        var form = new FormData();
        form.append("image", file);
        return axios.post("/logo", form);
    },
    createEducationImg(_context, file) {
        var form = new FormData();
        form.append("image", file);
        return axios.post("/eduimg", form);
    },
    tryLogin({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("/login", credentials)
                .then((response) => {
                    if (response.data.token) {
                        commit("SET_USER", response.data.user);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    logout({ commit }) {
        commit("SET_USER", false);
    },
    whoami({ commit }) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("/whoami")
                .then((response) => {
                    if (response.data) {
                        commit("SET_USER", response.data);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

//to handle mutations
const mutations = {
    SET_COMPANY(state, company) {
        state.company = company;
    },
    SET_COMPANIES(state, companies) {
        state.companies = companies;
    },
    SET_EMPLOYEES(state, employees) {
        state.employees = employees;
    },
    SET_NEW_ORDERS(state, newOrders) {
        state.newOrders = newOrders;
    },
    SET_BILLED_ORDERS(state, billed) {
        state.billedOrders = billed;
    },
    SET_PAID_ORDERS(state, paid) {
        state.paidOrders = paid;
    },
    SET_USERS(state, users) {
        state.users = users;
    },
    SET_PARTNERS(state, partners) {
        state.partners = partners;
    },
    SET_USER(state, user) {
        state.user = user;
    },
    SET_COURSES(state, courses) {
        state.courses = courses;
    },
    SET_COURSES_2(state, courses) {
        state.courses2 = courses;
    },
    SET_ENROLLMENTS(state, enrollments) {
        state.enrollments = enrollments;
    },
    SET_CATEGORIES(state, categories) {
        state.categories = categories;
    },
};

//export store module
export default {
    state,
    getters,
    actions,
    mutations,
};

//export default new Vuex.Store({
