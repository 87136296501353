<script>
import EventBus from "./eventBus";

export default {
    computed: {
        activeUser() {
            let user = this.$store.getters["getUser"];
            if (user) {
                return user;
            }
            return false;
        },
        currentLang() {
            return this.$i18n.locale;
        }
    },
    methods: {
        _formatDate: function (_date) {
            return this.$moment(_date, "YYYY-MM-DD hh:mm:ss").format(
                "DD.MM.YYYY"
            );
        },
        emit(action, variable) {
            EventBus.$emit(action, variable);
        },
        LOG(...vars) {
            console.log(vars);
        },
        ERROR(...vars) {
            console.error(vars);
        },
        toastSuccess(msg) {
            EventBus.$emit("toastSuccess", msg);
        },
        toastFailure(msg) {
            EventBus.$emit("toastFailure", msg);
        },
        goTo(routeName) {   
            this.$router.push({ name: routeName });
        },
        goToWithId(routeName,id) {   
            this.$router.push({ name: routeName, params: { id: id }});
        },
        linkTo(routeName) {
            return {
                name: this.currentLang + '-' + routeName
            }
        },
        routeTo(routeName) {
            this.$router.push({ name: this.currentLang + '-' + routeName });
        }
    },
};
</script>
