export default [
    {
        path: "/",
        name: "FI-Home",
        component: () => import("@/views/common/Home"),
    },
    {
        path: "/profiili",
        name: "fi-Profile",
        component: () => import("@/views/common/Profile"),
    },
    {
        path: "/admin-users",
        name: "FI-UserAdmin",
        component: () => import("@/views/admin/AdminUserRegister"),
    },
    {
        path: "/admin-reporting",
        name: "FI-AdminRaportit",
        component: () => import("@/views/admin/AdminReports"),
    },
    {
        path: "/yrityskayttajat",
        name: "Yrityskayttajat",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "company" */ "../views/company/UsersOfCompany.vue"),
      },
]