<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <button
                @click="toggleburger"
                role="button"
                :class="'navbar-burger ' + burgerclass"
                aria-label="menu"
                aria-expanded="false"
                data-target="ks-menu"
            >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </button>
        </div>

        <div
            @click="toggleburger"
            id="ks-menu"
            :class="'navbar-menu ' + burgerclass"
        >
            <router-link to="/" class="navbar-item bluebg navbar-home">
                <img src="/img/home-icon.png"/>
            </router-link>
            <div class="navbar-start">                                    
                <router-link to="/yrityskayttajat" class="navbar-item">{{
                    $t("message.main.menu.users")
                }}</router-link>
                <router-link to="/tyontekijat" class="navbar-item">{{
                    $t("message.main.menu.employees")
                }}</router-link>
                <router-link
                    to="/muutosturvatilaus"
                    class="navbar-item"
                    >{{
                        $t("message.main.menu.order-change-benefit")
                    }}</router-link
                >
                <router-link
                    to="/koulutusetutilaus"
                    class="navbar-item"
                    >{{
                        $t("message.main.menu.order-education-benefit")
                    }}</router-link
                >
            </div>
        </div>
    </nav>
</template>
<script>
export default {
    props: [
        'burgerclass'
    ],
    methods: {
        toggleburger() {
            this.$emit('toggleBurger');
        }
    }
}
</script>