import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ResetPassword from "@/views/ResetPassword";
Vue.use(VueRouter);

import FI from "@/router/fi.js";
import EN from "@/router/en.js";
const sekasoppa = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/reset-password/:resetToken",
    alias: "/aseta-uusi-salasana/:resetToken",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/admin-dashboard",
    name: "AdminDashboard",
    component: () =>
      import("../views/admin/AdminDashboard.vue"),
  },
  {
    path: "/admin-companies/:id",
    name: "AdminCompany",
    component: () =>
      import("../views/admin/AdminCompany.vue"),
  },
  {
    path: "/admin-companies/:id/employee/:employeeId",
    name: "AdminEmployee",
    component: () =>
      import("../views/admin/AdminEmployee.vue"),
  },
  {
    path: "/admin-companies/:id/user/:userId",
    name: "AdminUser",
    component: () =>
      import("../views/admin/AdminUser.vue"),
  },
  {
    path: "/admin-users/:userId",
    name: "AdminUserRegisterUser",
    component: () =>
      import("../views/admin/AdminUser.vue"),
  },
  {
    path: "/admin-companies",
    name: "AdminCompanies",
    component: () =>
      import("../views/admin/AdminCompanies.vue"),
  },
  {
    path: "/admin-education-partners",
    name: "AdminEducationPartners",
    component: () =>
      import("../views/admin/AdminEducationPartners.vue"),
  },
  {
    path: "/admin-education-partners/:id",
    name: "AdminEducationPartner",
    component: () =>
      import("../views/admin/AdminEducationPartner.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Dashboard.vue"),
  },
  {
    path: "/muokkaa-kumppani/:education_partner_id",
    name: "MuokkaaKumppani",
    component: () =>
      import("../views/MuokkaaKumppani.vue"),
  },
  {
    path: "/kumppani",
    name: "Kumppani",
    component: () =>
      import("../views/Kumppani.vue"),
  },
  {
    path: "/kumppanit",
    name: "Kumppanit",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Kumppanit.vue"),
  },
  {
    path: "/lisaa-koulutus",
    name: "Lisaa-Koulutus",
    component: () => import("../views/LisaaKoulutus.vue"),
  },
  {
    path: "/muokkaa-koulutus/:education_id",
    name: "Muokkaa-Koulutus",
    component: () => import("../views/MuokkaaKoulutus.vue"),
  },
  {
    path: "/yritykset",
    name: "Yritykset",
    component: () =>
      import("../views/Yritykset.vue"),
  },
  {
    path: "/yritys",
    name: "Yritys",
    component: () =>
      import("../views/Yritys.vue"),
  },
  {
    path: "/muokkaa-yritys/:company_id",
    name: "MuokkaaYritys",
    component: () =>
      import("../views/MuokkaaYritys.vue"),
  },
  {
    path: "/kayttajat",
    name: "Kayttajat",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Kayttajat.vue"),
  },
 
  {
    path: "/kayttaja/:user_id",
    name: "Kayttaja",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Kayttaja.vue"),
  },
  {
    path: "/tyontekijat",
    name: "Tyontekijat",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Tyontekijat.vue"),
  },
  {
    path: "/muutosturvatilaus",
    name: "MuutosturvaTilaus",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "yritysnakymat" */ "../views/yritysnakymat/TilauslomakeMuutosturva.vue"),
    props: {
      saldoType: "muutosturva",
    },
  },
  {
    path: "/koulutusetutilaus",
    name: "KoulutusEtutilaus",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SaldonTilaus.vue"),
    props: {
      saldoType: "koulutusetu",
      headerMsg: "message.order.koulutusetu.header",
    },
  },
  {
    path: "/saldontilaus-kiitos",
    name: "SaldonTilausKiitos",
    component: () => import("../views/SaldonTilaus-kiitos.vue"),
    props: {
      saldoType: "koulutusetu",
      headerMsg: "message.order.koulutusetu.header",
    },
  },
  {
    path: "/koulutukset",
    name: "Koulutukset",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Koulutukset.vue"),
  },
  {
    path: "/koulutus-seteli-how-to",
    name: "KoulutusSeteliHowTo",
    component: () => import("../views/KoulutusSeteliHowTo.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/koulutus/:education_id",
    name: "Koulutus",
    component: () => import("../views/Koulutus.vue"),
  },
  {
    path: "/koulutus-kiitos/:education_id",
    name: "Koulutus-kiitos",
    component: () => import("../views/Koulutus-kiitos.vue"),
  },
  {
    path: "/rekisteroi-yritys",
    name: "Rekisteroi-yritys",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Rekisteroi-yritys.vue"),
  },
  {
    path: "/rekisteroi-kayttaja/:hash?",
    name: "Rekisteroi-kayttaja",
    component: () => import("../views/Rekisteroi-kayttaja.vue"),
  },
  {
    path: "/rekisteroi-kayttaja-kiitos",
    name: "Rekisteroi-kayttaja-kiitos",
    component: () => import("../views/Rekisteroi-kayttaja-kiitos.vue"),
  },
  {
    path: "/rekisteroi-yritys-kiitos",
    name: "Rekisteroi-yritys-kiitos",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Rekisteroi-yritys-kiitos.vue"
      ),
  },
  {
    path: "/hae-koulutuskumppaniksi",
    name: "Hae-koulutuskumppaniksi",
    component: () => import("../views/Hae-koulutuskumppaniksi.vue"),
  },
  {
    path: "/hae-koulutuskumppaniksi-kiitos",
    name: "Hae-koulutuskumppaniksi-kiitos",
    component: () => import("../views/Hae-koulutuskumppaniksi-kiitos.vue"),
  },
];

const routes = FI.concat(sekasoppa).concat(EN);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
