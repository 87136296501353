<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <button
                @click="toggleburger"
                role="button"
                :class="'navbar-burger ' + burgerclass"
                aria-label="menu"
                aria-expanded="false"
                data-target="ks-menu"
            >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </button>
        </div>
        <div
            @click="toggleburger"
            id="ks-menu"
            :class="'navbar-menu ' + burgerclass"
        >
            <router-link to="/admin-dashboard" class="navbar-item bluebg navbar-home"><img src="/img/home-icon.png"/></router-link>
            <router-link to="/admin-companies" class="navbar-item">
                Yritykset
            </router-link>
            <router-link to="/admin-education-partners" class="navbar-item">
                Kumppanit
            </router-link>
            <router-link to="/admin-users" class="navbar-item">
                Käyttäjät
            </router-link>
            <router-link to="/admin-reporting" class="navbar-item">
                Raportit
            </router-link>                  
        </div>
    </nav>
</template>
<script>
export default {
    props: ['burgerclass'],
    
    methods: {
        toggleburger() {
            this.emit("toggleBurger");
        },
        logout() {
            this.emit("logout");
        },
    },
};
</script>
