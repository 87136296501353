import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from "buefy";
import axios from "axios";
import Vuex from "vuex";
import vuex_store from "./store";
import VueI18n from "vue-i18n";
import moment from "vue-moment";
import CountryFlag from 'vue-country-flag'

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
window.axios = axios;
//Vue.prototype.axios = window.axios;

Vue.use(Buefy);
Vue.config.productionTip = false;

Vue.use(Vuex);
var store = new Vuex.Store(vuex_store);

Vue.use(VueI18n);

/*
router.afterEach((to, from) => {
  
})*/

Vue.component('country-flag', CountryFlag)

import Global from "./Global";
Vue.mixin(Global);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
const i18n = new VueI18n({
  locale: "fi",
  messages: loadLocaleMessages(),
});

Vue.use(moment);

new Vue({
  mixins: [Global],
  router,
  store,
  render: (h) => h(App),
  i18n,
}).$mount("#app");
